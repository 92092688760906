import { IApplicationCustomVariablesValues } from 'app/interfaces/customVariablesInterfaces';
import { BaseHttpService } from 'app/services';
import { TSubscriber } from 'modules/products/pages/ApplicationRequest/SubscribersStep/SubscribersAddNewSideSheet';
import { ApplicationBeneficiaryAccount } from '../hooks/useApplicationsService';

export type TApplicationParametersDataRequest = {
  amount: number;
  numPayments: number;
  tacAmount?: number;
  tacRate?: number;
  interestRate: number;
  financeFee: number;
  firstPaymentDate: string;
  disbursementDate: string;
  borrowerId: string;
  productId: string;
  beneficiaryAccountId?: string;
  fundingId: string;
  customVariables: IApplicationCustomVariablesValues[] | null;
  subscribers: TSubscriber[] | null;
  signatureType: string;
  paymentMethod?: 'PIX' | 'TED';
  signatureCollectOptions?: {
    requireSelfPhoto?: boolean;
    requireSelfieValidation?: boolean;
  };
};

export class ApplicationsHttpService extends BaseHttpService {
  BASE_URL = `${process.env.REACT_APP_DASHBOARD_API_HOST}/proxy`;
  createApplication = async (
    applicationParametersData: TApplicationParametersDataRequest,
  ) => {
    const { subscribers } = applicationParametersData;
    const parsedCustomVariables = {};
    applicationParametersData?.customVariables
      ? applicationParametersData?.customVariables.map((cVariable) => {
          if (
            cVariable.value?.trim?.() !== '' &&
            cVariable.value !== null &&
            cVariable.value !== undefined
          ) {
            Object.assign(parsedCustomVariables, {
              [cVariable.name || '']: cVariable.value,
            });
          }
        })
      : [];

    const coSigners = subscribers
      ?.filter((subscriber) => subscriber.subscriberType === 'co_signers')
      .map((subscriber) => ({
        co_signer_id: subscriber.subscriber.id,
        type: subscriber.subscriberPersonType,
      }));

    const guarantors = subscribers
      ?.filter((subscriber) => subscriber.subscriberType === 'guarantors')
      .map((subscriber) => ({
        guarantor_id: subscriber.subscriber.id,
        type: subscriber.subscriberPersonType,
      }));

    const res = await this.post(`${this.BASE_URL}/originator/applications`, {
      requested_amount: applicationParametersData.amount,
      interest_rate: applicationParametersData.interestRate,
      tac_amount: applicationParametersData.tacAmount,
      tac_rate: applicationParametersData.tacRate,
      finance_fee: applicationParametersData.financeFee,
      num_payments: applicationParametersData.numPayments,
      first_payment_date: applicationParametersData.firstPaymentDate,
      disbursement_date: applicationParametersData.disbursementDate,
      borrower: {
        id: applicationParametersData.borrowerId,
      },
      product: {
        id: applicationParametersData.productId,
      },
      signature_collect_method:
        applicationParametersData.signatureType || 'LINK',
      co_signers: coSigners || undefined,
      guarantors: guarantors || undefined,
      payment_method: applicationParametersData.paymentMethod,
      beneficiary_account: applicationParametersData.beneficiaryAccountId
        ? {
            registered_account_id:
              applicationParametersData.beneficiaryAccountId,
          }
        : undefined,
      funding: {
        id: applicationParametersData.fundingId,
      },
      custom_variables: {
        values: {
          ...parsedCustomVariables,
        },
      },
      ...(applicationParametersData?.signatureCollectOptions && {
        signature_collect_options: {
          require_self_photo:
            applicationParametersData?.signatureCollectOptions
              ?.requireSelfPhoto,
          require_selfie_validation:
            applicationParametersData?.signatureCollectOptions
              ?.requireSelfieValidation,
        },
      }),
    });
    return res;
  };

  getApplication = async (applicationId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/applications/${applicationId}`,
    );
    return res;
  };

  getBasePremiumAmount = async (financedAmount: number, fundingId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/applications/preview-premium-amount`,
      {
        financed_amount: financedAmount,
        funding_id: fundingId,
      },
    );
    return res;
  };

  getApplications = async (
    page: number,
    size?: number,
    parameters?: { [key: string]: string | null | undefined },
    filterStatusSelect?: string[],
    filterIds?: string[],
  ) => {
    const filterStatusSelectParsed: string[] = [];
    const filterIdsParsed: string[] = [];

    filterStatusSelect &&
      filterStatusSelect.map((item) => {
        filterStatusSelectParsed.push(`&status=${item}`);
      });

    filterIds &&
      filterIds.map((item) => {
        filterIdsParsed.push(`&ids=${item}`);
      });

    const res = await this.get(
      `${this.BASE_URL}/originator/applications?page=${page}&size=${
        size || 10
      }&${
        parameters
          ? Object.entries(parameters)
              .filter((item) => item?.[1])
              .map((item) => {
                return `${item?.[0]}=${item?.[1]}`;
              })
              .join('&')
          : ''
      }${filterStatusSelectParsed
        .toString()
        .replaceAll(',', '')}${filterIdsParsed.toString().replaceAll(',', '')}`,
    );
    return res;
  };

  cancelApplication = async (applicationId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/applications/${applicationId}/cancel`,
      {},
    );
    return res;
  };

  getApplicationStatusOptions = async (productId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/issuer/products/${productId}/statuses-definitions`,
    );
    return res;
  };

  getQualificationRequestApplication = async (
    productId: string,
    page: number,
    borrowerId?: string,
    applicationId?: string,
  ) => {
    const res = await this.get(
      `${
        this.BASE_URL
      }/originator/products/${productId}/qualification-requests?role=APPLICATION&page=${page}&size=10${
        typeof borrowerId === 'string' ? `&borrower_id=${borrowerId}` : ''
      }${
        typeof applicationId === 'string'
          ? `&application_id=${applicationId}`
          : ''
      }`,
    );
    return res.data;
  };

  getApplicationSignatures = async (applicationId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/applications/${applicationId}/signatures`,
    );
    return res.data;
  };

  getAgreementFile = async (applicationId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/applications/${applicationId}/agreement`,
    );
    return res.data;
  };

  getSignedAgreementFile = async (applicationId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/applications/${applicationId}/signed-agreement/presigned-url`,
    );
    return res.data;
  };

  getSignedEndorsementFile = async (applicationId: string) => {
    const res = await this.get(
      `${this.BASE_URL}/originator/applications/${applicationId}/signed-endorsement`,
    );
    return res.data;
  };

  updateApplicationPaymentOrder = async (
    applicationId: string,
    paymentOrderId: string,
    data: ApplicationBeneficiaryAccount,
  ) => {
    const res = await this.put(
      `${this.BASE_URL}/originator/applications/${applicationId}/payment-orders/${paymentOrderId}`,
      data,
    );
    return res.data;
  };

  createDisbursementAttempt = async (applicationId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/applications/${applicationId}/disbursement-attempts`,
      {},
    );
    return res.data;
  };

  commercialPaperStatusUpdate = async (applicationId: string) => {
    const res = await this.post(
      `${this.BASE_URL}/originator/applications/${applicationId}/commercial-paper-status-update`,
      {},
    );
    return res.data;
  };
}
