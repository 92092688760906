import {
  BoxSection,
  ButtonDefault,
  ButtonPrimary,
  ConfirmModal,
  Heading3,
  Heading4,
  TextL,
  TextM,
} from 'app/components';
import { Divider, Theme, Tooltip, useTheme } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import {
  DisbursementDatesSelector,
  ReviewSimulationBox,
  ReviewSimulationPreviewItem,
  ReviewSimulationPreviewItems,
  ReviewStepBorrowerBox,
  ReviewStepBox,
  ReviewStepContent,
  SignTypeContainer,
  TedModalContent,
} from './styles';
import { ApplicationRequestContext } from '../ApplicationRequest';
import { StepContentButtonsArea } from '../styles';
import { ArrowBack, InfoOutlined } from '@mui/icons-material';
import {
  formatCurrency,
  normalizeAddDigits,
  normalizeCpfCnpj,
} from 'app/utils/normalizer';
import { formatDateFromBackend } from 'app/helpers/dateHelpers';
import { useApplicationsService } from 'modules/products/services/hooks';
import { CopyIcon, HandCoinBiggerIcon } from 'app/components/Icons';
import { TBorrowerType } from '../BorrowerStep/BorrowerStep';
import {
  ACCOUNT_TYPE_OBJECT,
  BANK_LIST_OBJECT,
  formatCNPJ,
  formatCPF,
  formatPhone,
} from 'app/helpers';
import { useNavigate } from 'react-router-dom';
import { SubscriberName } from '../SubscribersStep/SubscribersStep';
import { Table, CustomRadioInput } from 'celcoin-design-system';
import { useSnackbar } from 'app/hooks/useSnackbar';
import {
  TableBodyCellContainer,
  TableHeadCellContainer,
} from 'app/styles/components/Table';
import { PixType } from 'modules/customer/context/CustomerProvider/customer.interfaces';
import { useAppContext } from 'app/context/AppContextProvider';
import { differenceInDays, format, isSameDay, startOfDay } from 'date-fns';
import AttentionCircularIcon from 'app/components/Icons/AttentionCircularIcon';
import { CustomSelect } from 'app/components/CustomSelect';
import useCreditSimulator from 'app/hooks/useCreditSimulator';
import { Accordion } from 'app/components/Accordion';

enum PaymentMethod {
  PIX = 'PIX',
  TED = 'TED',
}

enum TedErrorType {
  BORROWER,
  HOUR,
}

const ReviewStepSimulationDivider = ({ theme }: { theme: Theme }) => (
  <Divider
    sx={{
      backgroundColor: theme.palette?.brand.primary.base,
      marginTop: '12px',
      opacity: 0.16,
    }}
  />
);

const tableHeadContent = [
  { id: '1', content: null },
  {
    id: '2',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Valor no vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '3',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Amortização</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '4',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Juros</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">IOF</TextM>
      </TableHeadCellContainer>
    ),
  },
  {
    id: '5',
    content: (
      <TableHeadCellContainer>
        <TextM weight="Bold">Vencimento</TextM>
      </TableHeadCellContainer>
    ),
  },
];

const ReviewStep = () => {
  const {
    simulation,
    simulationRequest,
    product,
    borrowerData,
    subscribers,
    customVariables,
    hasCustomVariables,
    funding,
    beneficiaryAccount,
    basePremiumAmount,
    resimulation,
    simulateWithDifferentDisbursementDate,
    onBack,
    onBackTwoSteps,
  } = useContext(ApplicationRequestContext);
  const { feature_flags } = useAppContext();
  const [isConfirmModalOpen, toggleConfirmModalOpen] = useState(false);
  const [selectedSignatureType, setSelectedSignatureType] = useState('LINK');
  const [tedErrorType, setTedErrorType] = useState<TedErrorType | undefined>();
  const [paymentMethod, setSelectedPaymentMethod] = useState<
    undefined | PaymentMethod
  >();
  const { applicationLoading, createApplication } = useApplicationsService();
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const { showSnackbarCopy } = useSnackbar();
  const { simulationLoading } = useCreditSimulator();
  const theme = useTheme();
  const navigate = useNavigate();
  const borrowerType: TBorrowerType = product?.borrower_type as TBorrowerType;
  const tableHeader = [
    'Função',
    'Pessoa',
    borrowerType === 'PERSON' ? 'CPF' : 'CNPJ',
    'Telefone',
    'E-mail',
  ];

  const isPaymentMethodVisible = useMemo(() => {
    return feature_flags?.PORTAL_TED_ENABLED
      ? feature_flags?.PORTAL_TED_ENABLED?.isActive
      : false;
  }, [feature_flags]);

  const onCreateApplication = useCallback(() => {
    if (simulationRequest && product) {
      createApplication(
        simulationRequest,
        product.id,
        borrowerData?.id || '',
        funding as string,
        customVariables,
        subscribers,
        selectedSignatureType,
        beneficiaryAccount,
        paymentMethod,
      ).then((application) => {
        if (application !== null) {
          navigate(`/products/application/${application.id}/signatures`);
        }
        toggleConfirmModalOpen(false);
      });
    }
  }, [
    simulationRequest,
    product,
    selectedSignatureType,
    beneficiaryAccount,
    paymentMethod,
  ]);

  const onChangePaymentMethod = useCallback(
    (method: PaymentMethod) => {
      if (method === PaymentMethod.TED) {
        if (
          !borrowerData?.external_bank_account ||
          !borrowerData?.external_bank_account.bank_account
        ) {
          setTedErrorType(TedErrorType.BORROWER);
          return;
        }

        if (
          !simulationRequest?.disbursementDate ||
          (isSameDay(
            new Date(),
            new Date(simulationRequest?.disbursementDate),
          ) &&
            new Date().getHours() >= 16)
        ) {
          setTedErrorType(TedErrorType.HOUR);
          return;
        }
      }
      setSelectedPaymentMethod(method);
    },
    [borrowerData, simulationRequest],
  );

  const handleSelectSignatureType = (value: string) => {
    setSelectedSignatureType(value);
  };

  const isPerson = () => {
    return borrowerType === 'PERSON';
  };

  const baseIofPerInstallment = useMemo(() => {
    const sum_running_iof =
      simulation?.schedule?.reduce((sum, j) => sum + j.iof, 0) || 0;

    if (!simulation?.iofAmount || !simulation.numPeriods) {
      return 0;
    }

    return (simulation?.iofAmount - sum_running_iof) / simulation.numPeriods;
  }, [simulation]);

  const handleCopySchedule = useCallback(() => {
    const header =
      'Parcela\tValor no vencimento\tAmortização\tJuros\tIOF\tVencimento\r\n';
    const records = simulation?.schedule
      ?.map((schedule, inx) => {
        const { payment, principal, interest, payment_date, iof } = schedule;
        const finalIof = baseIofPerInstallment + iof;
        return `${
          inx + 1
        }\t${payment.toLocaleString()}\t${principal.toLocaleString()}\t${interest.toLocaleString()}\t${finalIof}\t${payment_date}`;
      })
      .join('\r\n');

    navigator.clipboard.writeText(`${header}${records}`);
    showSnackbarCopy();
  }, [simulation, baseIofPerInstallment]);

  const getFormatedPix = (pixKey?: string, pixType?: PixType): string => {
    if (!pixKey || !pixType || pixKey.length === 0 || pixType.length === 0)
      return '-';
    if (pixType === PixType.TAXPAYER_ID) return normalizeCpfCnpj(pixKey);
    if (pixType === PixType.PHONE_NUMBER) return formatPhone(pixKey);
    return pixKey;
  };

  const cessionValue = useMemo(() => {
    if (!simulation) return 0;
    const tacAmount = product?.tac_not_included_cession_amount
      ? 0
      : simulation.tacAmount || 0;
    return (
      (simulation.requested_amount ?? 0) +
      tacAmount +
      (simulation.finance_fee ?? 0) +
      (simulation.iofAmount ?? 0) +
      (basePremiumAmount ?? 0) -
      (simulation?.discount_amount ?? 0)
    );
  }, [simulation, basePremiumAmount, product]);

  const finalSimulation = resimulation === null ? simulation : resimulation;

  return (
    <BoxSection boxVariant="rounded">
      <ReviewStepContent>
        <Heading3>Ficou tudo certo?</Heading3>
        <ReviewStepBox marginTop="32px" marginBottom="16px">
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
            }}
          >
            Lista de assinaturas:
          </Heading4>
          <ReviewStepBorrowerBox>
            <Table
              tableHeadCell={tableHeader.map((header, index) => ({
                id: String(index),
                content: (
                  <TableHeadCellContainer>
                    <TextM weight="Bold">{header}</TextM>
                  </TableHeadCellContainer>
                ),
              }))}
              tableBodyContent={[
                {
                  id: '1',
                  cells: [
                    {
                      id: '1',
                      content: (
                        <TableBodyCellContainer>
                          <TextM weight="Bold">Tomador</TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '2',
                      content: (
                        <TableBodyCellContainer>
                          <TextM weight="Regular">
                            {isPerson()
                              ? borrowerData?.full_name
                              : borrowerData?.legal_name}
                          </TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '3',
                      content: (
                        <TableBodyCellContainer>
                          <TextM weight="Regular">
                            {isPerson()
                              ? formatCPF(borrowerData?.taxpayer_id || '')
                              : formatCNPJ(borrowerData?.taxpayer_id || '')}
                          </TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '4',
                      content: (
                        <TableBodyCellContainer>
                          <TextM weight="Regular">
                            {formatPhone(
                              (borrowerData?.phone?.area_code || '') +
                                (borrowerData?.phone?.number || ''),
                            )}
                          </TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                    {
                      id: '5',
                      content: (
                        <TableBodyCellContainer>
                          <TextM weight="Regular">
                            {borrowerData?.email_address}
                          </TextM>
                        </TableBodyCellContainer>
                      ),
                    },
                  ],
                },
              ].concat(
                subscribers?.map(
                  // ({ subscriber, subscriberType, subscriberPersonType }) => {
                  ({ subscriber, subscriberType }) => {
                    return {
                      id: '1',
                      cells: [
                        {
                          id: '1',
                          content: (
                            <TableBodyCellContainer>
                              <TextM weight="Bold">
                                {SubscriberName[subscriberType]}
                              </TextM>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '2',
                          content: (
                            <TableBodyCellContainer>
                              <TextM weight="Regular">
                                {/* {subscriberPersonType === 'PERSON'
                                ? subscriber?.full_name
                              : subscriber?.legal_name} */}
                                {subscriber?.full_name}
                              </TextM>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '3',
                          content: (
                            <TableBodyCellContainer>
                              <TextM weight="Regular">
                                {/* {subscriberPersonType === 'PERSON'
                                ? formatCPF(subscriber?.taxpayer_id || '')
                              : formatCNPJ(subscriber?.taxpayer_id || '')} */}
                                {formatCPF(subscriber?.taxpayer_id || '')}
                              </TextM>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '4',
                          content: (
                            <TableBodyCellContainer>
                              <TextM weight="Regular">
                                {formatPhone(
                                  (subscriber?.phone?.area_code || '') +
                                    (subscriber?.phone?.number || ''),
                                )}
                              </TextM>
                            </TableBodyCellContainer>
                          ),
                        },
                        {
                          id: '5',
                          content: (
                            <TableBodyCellContainer>
                              <TextM weight="Regular">
                                {subscriber?.email_address}
                              </TextM>
                            </TableBodyCellContainer>
                          ),
                        },
                      ],
                    };
                  },
                ) || [],
              )}
            />
          </ReviewStepBorrowerBox>
        </ReviewStepBox>
        {beneficiaryAccount && (
          <ReviewStepBox marginTop="0px" marginBottom="16px">
            <Heading4
              style={{
                color: theme.palette.brand.secondary.base,
              }}
            >
              Conta Beneficiária
            </Heading4>
            <ReviewSimulationBox>
              <ReviewSimulationPreviewItems>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Descrição
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.description}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Nome
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.holder.name}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.holder.taxpayer_id.length > 11
                        ? 'CNPJ'
                        : 'CPF'}
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {normalizeCpfCnpj(beneficiaryAccount.holder.taxpayer_id)}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Pix
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.pix
                        ? getFormatedPix(
                            beneficiaryAccount.pix.key,
                            beneficiaryAccount.pix.key_type as PixType,
                          )
                        : '-'}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
              </ReviewSimulationPreviewItems>
              <ReviewSimulationPreviewItems>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Banco
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.external_bank_account?.ispb_code
                        ? BANK_LIST_OBJECT[
                            beneficiaryAccount.external_bank_account.ispb_code
                          ]
                          ? `${
                              BANK_LIST_OBJECT[
                                beneficiaryAccount.external_bank_account
                                  .ispb_code
                              ]
                            } (${
                              beneficiaryAccount.external_bank_account.ispb_code
                            })`
                          : '-'
                        : '-'}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Conta
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.external_bank_account?.bank_account
                        ? `${beneficiaryAccount.external_bank_account.bank_account}-${beneficiaryAccount.external_bank_account.bank_account_digit}`
                        : '-'}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Agência
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.external_bank_account?.bank_branch
                        ? beneficiaryAccount.external_bank_account.bank_branch
                        : '-'}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
                <>
                  <ReviewSimulationPreviewItem>
                    <TextM
                      weight="Semibold"
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      Tipo
                    </TextM>
                    <TextM
                      style={{ color: theme.palette.brand.secondary.base }}
                    >
                      {beneficiaryAccount.external_bank_account
                        ?.bank_account_type
                        ? ACCOUNT_TYPE_OBJECT[
                            beneficiaryAccount.external_bank_account
                              .bank_account_type
                          ] ?? '-'
                        : '-'}
                    </TextM>
                  </ReviewSimulationPreviewItem>
                  <ReviewStepSimulationDivider theme={theme} />
                </>
              </ReviewSimulationPreviewItems>
            </ReviewSimulationBox>
          </ReviewStepBox>
        )}
        <ReviewStepBox marginTop="0px" marginBottom="16px">
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
            }}
          >
            Valores da solicitação:
          </Heading4>
          <ReviewSimulationBox>
            <ReviewSimulationPreviewItems>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Quant. parcelas
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.numPeriods && simulation?.numPeriods > 0
                      ? simulation?.numPeriods
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Valor da parcela
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.paymentAmount
                      ? formatCurrency(simulation?.paymentAmount)
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Valor liberado
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.requested_amount
                      ? formatCurrency(simulation?.requested_amount)
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Valor financiado
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.financed_amount
                      ? formatCurrency(simulation?.financed_amount || 0)
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Juros
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.interestRate
                      ? `${normalizeAddDigits(
                          simulation?.interestRate * 100,
                          2,
                        )?.replace('.', ',')}% a.m.`
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    CET
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {typeof simulation?.monthlyEffectiveInterestRate ===
                    'number'
                      ? `${normalizeAddDigits(
                          simulation?.monthlyEffectiveInterestRate * 100,
                          2,
                        ).replace('.', ',')}% a.m.`
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
            </ReviewSimulationPreviewItems>
            <ReviewSimulationPreviewItems>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    TC
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.tacAmount
                      ? formatCurrency(simulation?.tacAmount)
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    IOF
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.iofAmount
                      ? formatCurrency(simulation?.iofAmount)
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Primeiro vencimento
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.firstPaymentDate
                      ? formatDateFromBackend(
                          simulation?.firstPaymentDate,
                        ).toLocaleDateString('pt-br')
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Último vencimento
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.lastPaymentDate
                      ? formatDateFromBackend(
                          simulation?.lastPaymentDate,
                        ).toLocaleDateString('pt-br')
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
              <>
                <ReviewSimulationPreviewItem>
                  <TextM
                    weight="Semibold"
                    style={{ color: theme.palette.brand.secondary.base }}
                  >
                    Saldo Total
                  </TextM>
                  <TextM style={{ color: theme.palette.brand.secondary.base }}>
                    {simulation?.totalAmountOwed
                      ? formatCurrency(simulation?.totalAmountOwed)
                      : '-'}
                  </TextM>
                </ReviewSimulationPreviewItem>
                <ReviewStepSimulationDivider theme={theme} />
              </>
            </ReviewSimulationPreviewItems>
          </ReviewSimulationBox>
        </ReviewStepBox>
        <ReviewStepBox marginTop="16px" marginBottom="16px" padding="30px 40px">
          <Heading4
            style={{
              color: theme.palette.brand.secondary.base,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Tooltip
              placement="bottom-start"
              title={
                <TextM style={{ color: theme.palette.common.white }}>
                  Este valor corresponde ao saldo necessário para liquidação da
                  CCB.
                </TextM>
              }
            >
              <InfoOutlined
                style={{
                  marginRight: '0.6rem',
                  color: theme.palette.brand.gray[900],
                }}
              />
            </Tooltip>
            <strong>Saldo para liquidação: </strong>
            <span style={{ paddingLeft: '0.4rem' }}>{` ${formatCurrency(
              cessionValue,
              true,
            )}`}</span>
          </Heading4>
        </ReviewStepBox>
        <ReviewStepBox marginTop="16px" marginBottom="16px" padding="24px 8px">
          <Accordion
            cleanStyle={true}
            isOpen={isAccordionOpen}
            title={<Heading4>Visualizar parcelas</Heading4>}
            onClick={() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {product?.multiple_disbursements_dates && simulation && (
              <DisbursementDatesSelector>
                <CustomSelect
                  labelValue="Selecione a data de desembolso"
                  options={new Array(
                    differenceInDays(
                      startOfDay(new Date(simulation.disbursement_date!)),
                      startOfDay(new Date()),
                    ) + 2,
                  )
                    .fill(0)
                    .map((_, index) => {
                      const date = new Date();
                      date.setDate(date.getDate() + index);
                      return {
                        label: format(date, 'dd/MM/yyyy'),
                        value: startOfDay(date).toISOString(),
                      };
                    })}
                  handleInputChange={(evt) => {
                    const { value } = evt.currentTarget.value;
                    simulateWithDifferentDisbursementDate(value);
                  }}
                  isClearable={false}
                  value={
                    finalSimulation
                      ? {
                          label: format(
                            formatDateFromBackend(
                              finalSimulation.disbursement_date!,
                            ),
                            'dd/MM/yyyy',
                          ),
                          value: startOfDay(
                            formatDateFromBackend(
                              finalSimulation.disbursement_date!,
                            ),
                          ).toISOString(),
                        }
                      : undefined
                  }
                  name="disbursementScheduleDate"
                  placeholder="Selecionar"
                />
              </DisbursementDatesSelector>
            )}
            <div style={{ marginTop: '15px' }}>
              {finalSimulation?.schedule &&
              Array.isArray(finalSimulation?.schedule) &&
              finalSimulation?.schedule.length > 0 ? (
                <>
                  <Table
                    maxBodyHeight="55vh"
                    tableHeadSticky={true}
                    tableHeadCell={tableHeadContent}
                    isLoading={simulationLoading}
                    tableBodyContent={finalSimulation.schedule.map(
                      (simulationData, inx) => {
                        const iof = baseIofPerInstallment + simulationData.iof;
                        return {
                          id: String(inx),
                          cells: [
                            {
                              id: '1',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM
                                    style={{ margin: '0 auto' }}
                                    weight="Bold"
                                  >
                                    {inx + 1}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '2',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatCurrency(simulationData.payment)}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '3',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatCurrency(simulationData.principal)}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '4',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatCurrency(simulationData.interest)}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '5',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>{formatCurrency(iof)}</TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                            {
                              id: '6',
                              content: (
                                <TableBodyCellContainer>
                                  <TextM>
                                    {formatDateFromBackend(
                                      simulationData.payment_date,
                                    ).toLocaleDateString('pt-BR')}
                                  </TextM>
                                </TableBodyCellContainer>
                              ),
                            },
                          ],
                        };
                      },
                    )}
                    tableFooterCell={[
                      {
                        id: '1',
                        content: null,
                      },
                      {
                        id: '2',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    finalSimulation.schedule
                                      .map((schedule) => schedule.payment)
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '3',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    finalSimulation.schedule
                                      .map((schedule) => schedule.principal)
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '4',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    finalSimulation.schedule
                                      .map((schedule) => schedule.interest)
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '5',
                        content: (
                          <TableBodyCellContainer>
                            <div style={{ display: 'block' }}>
                              <div>
                                <TextM>Total</TextM>
                              </div>
                              <div>
                                <TextM weight="Bold">
                                  {formatCurrency(
                                    finalSimulation.schedule
                                      .map(
                                        (schedule) =>
                                          baseIofPerInstallment + schedule.iof,
                                      )
                                      .reduce((a, b) => a + b, 0),
                                  )}
                                </TextM>
                              </div>
                            </div>
                          </TableBodyCellContainer>
                        ),
                      },
                      {
                        id: '6',
                        content: null,
                      },
                    ]}
                  />
                  <ButtonPrimary
                    style={{
                      backgroundColor: 'transparent',
                      width: 'fit-content',
                      margin: '15px auto 0px',
                    }}
                    typeVariant="outline"
                    btnIcon={
                      <CopyIcon
                        color={theme.palette.brand.primary.base as string}
                      />
                    }
                    iconPosition="left"
                    onClick={handleCopySchedule}
                  >
                    <TextM
                      weight="Semibold"
                      style={{
                        marginLeft: '11px',
                        color: theme.palette.brand.primary.base,
                      }}
                    >
                      Copiar resultado
                    </TextM>
                  </ButtonPrimary>
                </>
              ) : (
                <TextM>
                  Nenhuma parcela disponível. Conclua primeiro a simulação no
                  step de valores da solicitação.
                </TextM>
              )}
            </div>
          </Accordion>
        </ReviewStepBox>
        {isPaymentMethodVisible && (
          <ReviewStepBox marginTop="16px" marginBottom="16px">
            <SignTypeContainer>
              <Heading4 style={{ color: theme.palette.brand.secondary.base }}>
                Método de pagamento:{' '}
              </Heading4>
              <div className="bullet-area">
                <div className="bullet-item">
                  <CustomRadioInput
                    isChecked={paymentMethod === 'PIX'}
                    handleClick={() => onChangePaymentMethod(PaymentMethod.PIX)}
                  />
                  <TextL>Pix</TextL>
                </div>
                <div className="bullet-item">
                  <CustomRadioInput
                    isChecked={paymentMethod === 'TED'}
                    handleClick={() => onChangePaymentMethod(PaymentMethod.TED)}
                  />
                  <TextL>TED</TextL>
                </div>
              </div>
            </SignTypeContainer>
          </ReviewStepBox>
        )}
        <ReviewStepBox marginTop="16px" marginBottom="32px">
          <SignTypeContainer>
            <Heading4 style={{ color: theme.palette.brand.secondary.base }}>
              Receber assinatura por:{' '}
            </Heading4>
            <div className="bullet-area">
              <div className="bullet-item">
                <CustomRadioInput
                  isChecked={selectedSignatureType === 'WHATSAPP'}
                  handleClick={() => handleSelectSignatureType('WHATSAPP')}
                />
                <TextL>WhatsApp</TextL>
              </div>
              <div className="bullet-item">
                <CustomRadioInput
                  isChecked={selectedSignatureType === 'EMAIL'}
                  handleClick={() => handleSelectSignatureType('EMAIL')}
                />
                <TextL>E-mail</TextL>
              </div>
              <div className="bullet-item">
                <CustomRadioInput
                  isChecked={selectedSignatureType === 'LINK'}
                  handleClick={() => handleSelectSignatureType('LINK')}
                />
                <TextL>Link</TextL>
              </div>
            </div>
          </SignTypeContainer>
        </ReviewStepBox>
        <StepContentButtonsArea>
          <ButtonDefault
            margin={true}
            onClick={() => (!hasCustomVariables ? onBackTwoSteps() : onBack())}
          >
            <ArrowBack />
          </ButtonDefault>
          <ButtonPrimary
            disabled={!simulation}
            onClick={() => toggleConfirmModalOpen(true)}
          >
            Concluir
          </ButtonPrimary>
        </StepContentButtonsArea>
      </ReviewStepContent>
      <ConfirmModal
        isOpen={isConfirmModalOpen !== false}
        icon={
          <HandCoinBiggerIcon
            color={theme.palette.brand.primary.base as string}
          />
        }
        title="Concluir solicitação e ir para página de assinaturas?"
        color="primary"
        btnConfirmText="Concluir"
        isLoading={applicationLoading}
        handleConfirm={onCreateApplication}
        handleClose={() => toggleConfirmModalOpen(false)}
        handleCancel={() => toggleConfirmModalOpen(false)}
      >
        Após a assinatura das partes, a solicitação será submetida para
        aprovação (se necessário).
      </ConfirmModal>
      <ConfirmModal
        isColumnButtons={true}
        isOpen={tedErrorType !== undefined}
        icon={<AttentionCircularIcon />}
        title={
          tedErrorType === TedErrorType.BORROWER
            ? 'Tomador não possui conta bancária'
            : 'Fora do Horário'
        }
        btnConfirmText="Entendi"
        handleConfirm={() => setTedErrorType(undefined)}
        handleClose={() => setTedErrorType(undefined)}
      >
        <TedModalContent>
          {tedErrorType === TedErrorType.BORROWER ? (
            <>
              <TextM style={{ lineHeight: '1.3rem' }}>
                O tomador{' '}
                <strong>
                  {borrowerType === 'PERSON'
                    ? borrowerData?.full_name
                    : borrowerData?.legal_name}
                </strong>{' '}
                não possui conta bancária cadastrada para o método de pagamento
                TED.
              </TextM>
              <a
                href={
                  borrowerType === 'PERSON'
                    ? `#/records/natural/${borrowerData?.id}/details`
                    : `#/records/legal/${borrowerData?.id}/details`
                }
                target="_blank"
                style={{
                  textDecoration: 'underline',
                  marginTop: '20px',
                }}
                className="link"
                rel="noreferrer"
              >
                Ir para o cadastro
              </a>
            </>
          ) : (
            <TextM style={{ lineHeight: '1.3rem' }}>
              A <strong>data de liberação do recurso</strong> será hoje e o
              método de pagamento TED só pode ser realizado até às 16h.
            </TextM>
          )}
        </TedModalContent>
      </ConfirmModal>
    </BoxSection>
  );
};

export default ReviewStep;
